<template>
  <div v-loading="loading">
    <div class="d-flex flex-wrap justify-content-center p-2 my-4" v-if="place && place.space_cnt">
      <span class="h5">Occupancy: <span class="h4 text-primary">{{place.occupancy}}</span>% ({{place.occupied}}/{{place.space_cnt}})</span>
    </div>
    <CRow class="justify-content-center">
      <CCol md="12" lg="5" v-for="(section, index) in sections" :key="`section-img-${index}`" class="my-2">
        <CCard>
          <CCardImg class="custom-img-cover" variant="top" :src="section.image" />
          <div style="background-color:#eee;">
            <CRow class="justify-content-between">
              <div class="text-primary mx-4 my-2 px-2">
                <b>Camera #{{section.ch}}</b><span class="ml-2 text-dark">{{section.section_name}}</span>
              </div>
              <div class="mx-4 my-1 px-2">
                <el-tag type="success">Online</el-tag>
              </div>
            </CRow>
            <div class="text-dark mx-2 px-2">
              Last Entry: {{section.enter || "-"}}
            </div>
            <div class="text-dark mx-2 mb-2 px-2 pb-2">
              Last Exit: {{section.exit || "-"}}
            </div>
          </div>
        </CCard>
      </CCol>
    </CRow>
    <CRow class="justify-content-center" v-if="!loading && sections.length <= 0">
      <CCol md="12" lg="5" class="mt-4">
        <CCard>
          <CCardImg class="custom-img-cover" variant="top" src="/img/no-camera.jpg" />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CameraView',

  props: {
    place: {type:Object},
    is_selected: {type:Boolean,default:false}
  },
  watch: {
    is_selected() {
      if (this.is_selected) {
        this.getSnapshots(this.$route.params.id);
      }
    }
  },
  data() {
    return {
      loading: false,
      sections: []
    }
  },
  methods: {
    getSnapshots(id) {
      this.loading = true;
      axios.get(`/api/parking/places/${id}/?snapshot=1`)
        .then(response => {
          this.sections = JSON.parse(JSON.stringify(response.data));
          this.sections.sort((a,b) => {
            if (a.ch-b.ch > 0) return 1;
            else return (-1);
          });
          this.sections.forEach(section => {
            section.image = 'data:image/jpeg;base64,' + section.image;
            if (section.enter) {
              section.enter = new Date(section.enter.replace(/\s/g, 'T'));
              section.enter = this.$utility.GetDateTimeStr("$day, $dd $month $yyyy $HH:$MM:$ss", section.enter);
            }
            if (section.exit) {
              section.exit = new Date(section.exit.replace(/\s/g, 'T'));
              section.exit = this.$utility.GetDateTimeStr("$day, $dd $month $yyyy $HH:$MM:$ss", section.exit);
            }
          })
        })
        .catch(error => {
          console.log(error);
          this.sections = [];
        })
        .finally(_ => {
          this.loading = false;
        })
    }
  }
}
</script>